import "vite/modulepreload-polyfill";

import "billboard.js/dist/billboard.css";
import "../css/weather-icons.css";
import "../css/main.css";

import bb, { line } from "billboard.js";

type Column = Array<string | number>;

declare global {
  interface Window {
    DataPoints: DataPoint[];
    BikeScores: BikeScore[];
  }
}

interface BikeScore {
  time: number;
  value: number;
}

interface DataPoint {
  time: number;
  temperature: number;
  apparentTemperature: number;
  precipProbability: number;
  windSpeed: number;
  windGust: number;
  humidity: number;
}

function addHourlyChart(dataPoints: DataPoint[], bikeScores: BikeScore[]) {
  const date: Column = ["date"];
  const temp: Column = ["temp"];
  const feelsLike: Column = ["feelsLike"];
  const precip: Column = ["precip"];
  const wind: Column = ["wind"];
  const windGust: Column = ["windGust"];
  const humidity: Column = ["humidity"];

  dataPoints.forEach(function (d) {
    date.push(d.time * 1000);
    temp.push(Math.round(d.temperature));
    feelsLike.push(Math.round(d.apparentTemperature));
    precip.push(Math.round(d.precipProbability * 100));
    wind.push(Math.round(d.windSpeed));
    windGust.push(Math.round(d.windGust));
    humidity.push(Math.round(d.humidity * 100));
  });

  const bike: Column = ["bike"];
  bikeScores.forEach((bs) => bike.push(bs.value));

  const chart = bb.generate({
    bindto: "#hourly-forecast-graph",
    data: {
      type: line(),
      x: "date",
      columns: [date, temp, feelsLike, precip, wind, windGust, humidity, bike],
      colors: {
        temp: "#F97316",
        feelsLike: "#F92D16",
        precip: "#3B82F6",
        bike: "#16A34A",
        wind: "#A855F7",
        windGust: "#581C87",
        humidity: "#DB2777",
      },
      names: {
        temp: "Current Temp",
        feelsLike: "Feels Like",
        precip: "Precip",
        wind: "Wind Speed",
        windGust: "Wind Gust",
        humidity: "Humidity",
        bike: "Bike Score",
      },
      hide: ["humidity", "windGust"],
    },
    axis: {
      x: {
        type: "timeseries",
        tick: {
          format: "%a %I %p ",
        },
      },
    },
    grid: {
      y: {
        show: true,
      },
    },
    point: {
      show: false,
    },
    tooltip: {
      format: {
        value(value: any, _ratio: number, id: string, _index: number): string {
          switch (id) {
            case "temp":
            case "feelsLike":
              return value + "&deg;";
            case "precip":
            case "humidity":
              return value + "%";
            case "wind":
            case "windGust":
              return value + " mph";
            case "bike":
              return (value as number).toFixed(0);
            default:
              return value;
          }
        },
      },
    },
  });

  window.addEventListener("resize", () => {
    chart.resize();
    chart.flush();
  });
}

const byId = (elementId: string) => document.getElementById(elementId);

function wireAlertDetail() {
  const alertBanner = document.querySelector(".alert-banner");

  alertBanner?.addEventListener("click", function () {
    const el = document.querySelector(".alerts");
    const label = document.querySelector(".alerts-toggle");

    if (el === null || label === null) {
      return;
    }

    if (el.classList.contains("hidden")) {
      el.classList.remove("hidden");
      label.innerHTML = "Hide Details";
    } else {
      el.classList.add("hidden");
      label.innerHTML = "Show Details";
    }
  });
}

function wireEditLocationForm() {
  const currentLocation = byId("current-location");
  const modalOverlay = byId("modal-overlay");
  const modalLocation = byId("modal-location");
  const editLocationInput = byId("edit-location-input") as HTMLInputElement;
  const editLocationClose = byId("edit-location-cancel");

  if (!currentLocation || !modalLocation || !modalOverlay || !editLocationInput || !editLocationClose) {
    return;
  }

  currentLocation.addEventListener("click", function () {
    modalOverlay.classList.remove("hidden");
    modalLocation.classList.remove("hidden");
    editLocationInput.focus();
    editLocationInput.select();
  });

  const closeModal = () => {
    modalOverlay.classList.add("hidden");
    modalLocation.classList.add("hidden");
  };

  modalOverlay.addEventListener("click", closeModal);
  editLocationClose.addEventListener("click", closeModal);
}

document.addEventListener("DOMContentLoaded", function () {
  addHourlyChart(window.DataPoints || [], window.BikeScores || []);
  wireAlertDetail();
  wireEditLocationForm();
});
